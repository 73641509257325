import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Signature from './signature';
import Redirect from './redirect';
import reportWebVitals from './reportWebVitals';
import { CriiptoVerifyProvider } from '@criipto/verify-react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { isAndroid, isIOS } from 'react-device-detect';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Signature />,
  },
  {
    path: "/callback",
    element: <Redirect />,
  },
]);

const resolveLoginHint = () => {
  if (isAndroid) {
    return "appswitch:android";
  }
  if (isIOS) {
    return "appswitch:ios";
  }

  return "";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <CriiptoVerifyProvider
          domain="airpark-test.criipto.id"
          clientID="urn:my:application:identifier:833481"
          redirectUri={window.location.origin + '/callback'}
          loginHint={resolveLoginHint()}
        >
        <RouterProvider router={router} />
      </CriiptoVerifyProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
